import type { PageType } from '../analytics'
import type { Brand, BrandId } from '../brands'
import { AbTestKeys } from '../constants/AbTestKeys'
import type { CountryConfiguration } from '../countryConfiguration'
import type { CustomDomainData } from './domainData/domainData'

export const enum Device {
	Mobile = 'mobile',
	Desktop = 'desktop',
}

export const enum Channel {
	Shop = 'shop',
	Outlet = 'outlet',
	Backoffice = 'backoffice',
}

export const enum Environment {
	Pro = 'pro',
	Pre = 'pre',
	Dev = 'dev',
	Local = 'local',
}

export const enum Platform {
	Web = 'web',
	/** @deprecated only on checkout */
	Ios = 'iphone os',
	/** @deprecated only on checkout */
	Android = 'android',
}

export type HeaderValue = string | null | undefined
export interface AbTestServer {
	enabled: boolean
	variables: { [variableKey: string]: unknown }
	FFKey: AbTestKeys
}

export interface MasterData {
	abTestPage: AbTestServer | null
	abTestLayout: AbTestServer | null
	brand: BrandId | null
	subBrand: BrandId | null
	h1LogoKey: string | null
	pageType: PageType
	device: Device
	isApp: boolean
	platform: Platform
	customDate: Date | null
	channel: Channel
	isShop: boolean
	isMobile: boolean
	isDesktop: boolean
	isCicd: boolean
	isQa: boolean
	isRevalidation: boolean
	referer: HeaderValue
	dev: HeaderValue
	url: HeaderValue
	region: HeaderValue
	country: CountryConfiguration & { brands: Brand[] }
	environment: Environment
	/** @deprecated only for error monitoring */
	userAgent?: HeaderValue
	/** For domain data use */
	custom?: CustomDomainData | null
	test: boolean
	shouldShowImportData?: boolean
}
