import { getMasterData } from 'master-data'

import { Types } from '../../constants/types'
import type { SendLogParams } from '../../types/ErrorsLogs'

export function sendLog({
	custom,
	domain,
	message,
	type = Types.Error,
}: SendLogParams): void {
	const {
		brand,
		isRevalidation,
		country: { countryISO, languageISO },
		device,
		userAgent,
		pageType,
		referer,
		url,
	} = getMasterData()
	const error = {
		country: countryISO,
		language: languageISO,
		name: '[Genesis]',
		brand,
		isRevalidation,
		custom,
		device,
		domain,
		userAgent,
		message,
		pageType,
		referer,
		type,
		url,
	}

	console.error(error)
}
