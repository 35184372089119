export enum ShopBrand {
	she = 'she',
	he = 'he',
	kids = 'kids',
	newBorn = 'newBorn',
	babyNina = 'babyNina',
	babyNino = 'babyNino',
	nino = 'nino',
	nina = 'nina',
	teen = 'teen',
	teenA = 'teenA',
	teenO = 'teenO',
	home = 'home',
}

export enum OutletBrand {
	outlet = 'outlet',
	outletH = 'outletH',
	outletA = 'outletA',
	outletO = 'outletO',
	outletBA = 'outletBA',
	outletBO = 'outletBO',
	outletT = 'outletT',
	outletQ = 'outletQ',
	outletP = 'outletP',
}

export const BrandIdList = [
	...Object.values(ShopBrand),
	...Object.values(OutletBrand),
] as const

export type BrandId = OutletBrand | ShopBrand

export interface Brand {
	id: BrandId
	labelKey: string
	urlKey: string
	subBrands?: Brand[]
}
