import { Domains } from 'monitoring/constants/domains'
import { Types } from 'monitoring/constants/types'
import { sendLog } from 'monitoring/sendLog/server/sendLog'
import type { Brand } from 'types/brands'

import { brandTranslationKeys } from '../../constants/brands'

export const mapLabelKeysToBrands = (brands: Brand[]): Brand[] =>
	brands.map((brand) => {
		const brandKeys = brandTranslationKeys[brand.id]

		if (!brandKeys) {
			sendLog({
				domain: Domains.CountryConfiguration,
				type: Types.Error,
				message: `Brand not found in translation keys map: [brandTranslationKeys]`,
				custom: `brand id: ${brand.id}`,
			})
		}

		if (brand.subBrands) {
			brand.subBrands = mapLabelKeysToBrands(brand.subBrands)
		}

		return {
			...brand,
			labelKey: brandKeys.labelKey,
			urlKey: brandKeys.urlKey,
		}
	})
